import React, { useEffect } from 'react';

import {
  shallowEqual,
  useDispatch,
  useSelector,
} from 'react-redux';

import { getCurrencyExchangeList } from '../../../actions/configAction';
import {
  getListReteFuenteAction,
  getListReteRentaAction,
} from '../../../actions/invoiceActions';
import ArticleView from '../../catalogCategory/article/article.view';
import { DocumentCartComponent } from './DocumentCartComponent';
import {
  getDocumentCartSections,
  getSectionsToShow,
} from './utils';

export const DocumentCartManage = ({ typedocument }) => {
  const dispatch = useDispatch();

  const { opdetalledocumento, showCatalog } = useSelector(
    (state) => state.documentCartReducer,
    shallowEqual
  );

  /**
   * * Realiza la carga inicial de datos al montar el componente.
   * @param {function} dispatch - Función de despacho de acciones Redux.
   */
  useEffect(() => {
    dispatch(getListReteFuenteAction());
    dispatch(getListReteRentaAction());
    dispatch(getCurrencyExchangeList());
  }, [dispatch]);

  return showCatalog ? (
    <ArticleView typeView="invoice" type={0} typedocument={typedocument} />
  ) : (
    <DocumentCartComponent
      opdetalledocumento={opdetalledocumento}
      sections={getDocumentCartSections({
        typedocument,
      })}
      showSections={getSectionsToShow(typedocument)}
      typedocument={typedocument}
    />
  );
};
